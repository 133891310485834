import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import FilterItem from "../components/FilterItem";
import RedirectHeader from "../components/RedirectHeader";
import { parseNameAndSurname } from "../utils/utils";
import { Link } from "gatsby";
import "./classes.sass";
import { isMobile } from "react-device-detect";
import filterIcon from "../img/Filters.png"
import cross from "../img/cross.svg"
import Button from "../components/Button";
import { set } from "lodash";

export const ClassesPageTemplate = ({ classes, classFilterOptions, dayFilterOptions, instructorFilterOptions }) => {
  const [classFilter, setClassFilter] = React.useState([]);
  const [instructorFilter, setInstructorFilter] = React.useState([]);
  const [dayFilter, setDayFilter] = React.useState([]);
  const [filteredClasses, setFilteredClasses] = React.useState(classes);
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
  const [filterCount, setFilterCount] = React.useState(0);


  React.useEffect(() => {
    const filteredClasses = classes.filter(classItem => {
      const { instructor, title } = classItem.node.frontmatter;
      const { eventDates } = classItem.node.frontmatter;
      const eventDays = eventDates.map(item => item.eventDay);


      const instructorFilterCorrect = instructorFilter.length === 0 || instructorFilter.includes(instructor[0].split("___").join(" "));
      const classFilterCorrect = classFilter.length === 0 || classFilter.includes(title);
      const dayFilterCorrect = dayFilter.length === 0 || dayFilter.some(item => eventDays.includes(item));
      return instructorFilterCorrect && classFilterCorrect && dayFilterCorrect;
    });
    setFilteredClasses(filteredClasses);
    setFilterCount(instructorFilter.length + classFilter.length + dayFilter.length);
  }, [classFilter, instructorFilter, dayFilter, classes]);

  const toggleFiters = () => {
    setIsFiltersOpen(!isFiltersOpen);
  }

  const clearFiters = () => {
    setClassFilter([]);
    setInstructorFilter([]);
    setDayFilter([]);
    setIsFiltersOpen(false);
  }



  return (
    <div className="section-container">
      <section className="section">
        <RedirectHeader title="Pracownie" route="/" showRedirect={false} />
        {
          !isMobile ? (
            <div className="filters-container">
              <FilterItem filter={classFilter} setFilter={setClassFilter} filterName="Pracownie" filterOptions={classFilterOptions} />
              <FilterItem filter={instructorFilter} setFilter={setInstructorFilter} filterName="Instruktor" filterOptions={instructorFilterOptions} />
              <FilterItem filter={dayFilter} setFilter={setDayFilter} filterName="Dzień" filterOptions={dayFilterOptions} />
            </div>
          ) : (
            <div className="mobile-filters-container">
              <p>{`Filtry ${filterCount > 0 ? "(" + filterCount + ")" : ""}`}</p>
              <img src={filterIcon} alt="filter icon" onClick={() => toggleFiters()} />
              {isFiltersOpen && (
                <div className="mobile-filters">
                  <div className="mobile-filters-header">
                    <p className="mobile-filters-title">Filtry</p>
                    <img src={cross} alt="cross icon" onClick={() => toggleFiters()} />
                  </div>
                  <FilterItem filter={classFilter} setFilter={setClassFilter} filterName="Pracownie" filterOptions={classFilterOptions} preSelected={classFilter} />
                  <FilterItem filter={instructorFilter} setFilter={setInstructorFilter} filterName="Instruktor" filterOptions={instructorFilterOptions} preSelected={instructorFilter} />
                  <FilterItem filter={dayFilter} setFilter={setDayFilter} filterName="Dzień" filterOptions={dayFilterOptions} preSelected={dayFilter} />
                  {filterCount > 0 ?
                    <Button text={`Wyczyść filtry ${filterCount > 0 ? "(" + filterCount + ")" : ""}`} onClick={() => clearFiters()} />
                    : null
                  }
                </div>
              )}
            </div>
          )
        }

        <div className="classes-container">

          {filteredClasses.map((classItem) => {
            const { instructor, title, image, description } = classItem.node.frontmatter;
            const { slug } = classItem.node.fields;
            return (
              <Link to={slug} className="class-item" key={classItem.node.id}>
                <div className="class-item-image">
                  <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} style={{ maxHeight: "300px", height: "300px" }} />
                  <h5 className="h5-black">{title}</h5>
                  <p>{`Zajęcia prowadzi ${parseNameAndSurname(instructor[0])}`}</p>
                  <p>{description.length > 75 ? description.substring(0, 75) + " [...]" : description}</p>
                </div>
                <h5 className="h5-black"></h5>
              </Link>
            )
          }
          )}
        </div>
      </section>
    </div>
  );
};

ClassesPageTemplate.propTypes = {
  classes: PropTypes.array,
  pageContext: PropTypes.object,
  classFilterOptions: PropTypes.array,
  dayFilterOptions: PropTypes.array,
  instructorFilterOptions: PropTypes.array,
};

const ClassesPage = ({ data, pageContext }) => {
  const classes = data.allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.templateKey === "class");
  const days = data.allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.templateKey === "weekDay");
  const instructors = data.allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.templateKey === "instructor");

  const classFilterOptions = Array.from(new Set(classes.map(edge => edge.node.frontmatter.title)));
  const dayFilterOptions = Array.from(new Set(days.map(edge => edge.node.frontmatter.name)));
  const instructorFilterOptions = Array.from(new Set(instructors.map(edge => edge.node.frontmatter.name + " " + edge.node.frontmatter.surname)));

  return (
    <Layout>
      <ClassesPageTemplate
        classes={classes}
        pageContext={pageContext}
        classFilterOptions={classFilterOptions}
        dayFilterOptions={dayFilterOptions}
        instructorFilterOptions={instructorFilterOptions}
      />
    </Layout>
  );
};

ClassesPage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ClassesPage;

export const pageQuery = graphql`
  query EventPageTemplate {
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {in: ["class", "weekDay", "instructor"]}}}
      sort: {fields: frontmatter___title}
      ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            name
            surname
            templateKey
            date
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            title
            publishDate
            instructor
            eventDates {
              eventDay
            }
          }
        }
      }
    }
  }
  `